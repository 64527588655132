import { atom, useSetAtom } from 'jotai';

import { useAtomicStateAtomValue } from 'src/atoms/atomic-state';

import { geographySearchAtom } from 'src/components/ReactGeographySearch/atoms';

import { useDeepEqualMemo } from 'src/hooks/useDeepEqualMemo';

export const hasMapCustomPositionAtom = atom(false);

// to emulate the atomic-state's watch function
// don't call it together hasMapCustomPositionAtom to avoid reset unwanted
export const useResetHasMapCustomPositionAtom = () => {
  const setHasMapCustomPosition = useSetAtom(hasMapCustomPositionAtom);
  const geographySearch = useAtomicStateAtomValue(geographySearchAtom);

  useDeepEqualMemo(() => {
    if (geographySearch) {
      setHasMapCustomPosition(false);
    }
  }, [geographySearch, setHasMapCustomPosition]);
};
