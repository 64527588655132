import { useAtomValue } from 'jotai';

import { useAtomicStateAtomValue } from 'src/atoms/atomic-state';

import { geographySearchParamsAtom } from 'src/components/GeographySearch/atoms';
import { mapBoundsAtom, mapZoomAtom } from 'src/components/MapComponent/atoms';

import { useDeepEqualMemo } from 'src/hooks/useDeepEqualMemo';
import { formSearchParamsAtom } from 'src/hooks/useRealEstateLiteSearch';

export const useRealEstateSearchParams = () => {
  const formSearchParams = useAtomicStateAtomValue(formSearchParamsAtom);
  const zoom = useAtomValue(mapZoomAtom);
  const mapBounds = useAtomValue(mapBoundsAtom);
  const geographySearchParams = useAtomicStateAtomValue(
    geographySearchParamsAtom
  );

  return useDeepEqualMemo(
    () => ({
      zoom,
      ...mapBounds,
      ...geographySearchParams,
      ...formSearchParams,
    }),
    [formSearchParams, geographySearchParams, mapBounds, zoom]
  );
};
