import { useMemo } from 'react';
import { useUserAdditionalData } from '@indomita-react/auth-provider';
import { useTranslations } from '@pepita-react/i18n';
import { useSuspenseQuery } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import type {
  GeographySurfaceParams,
  GeographySurfaceResponse,
} from 'src/repository/geography/api';

import { useAtomicStateAtomValue } from 'src/atoms/atomic-state';

import { geographySearchAtom } from 'src/components/ReactGeographySearch/atoms';
import { useGetModalFilterCount } from 'src/components/SearchFilters/hooks/useGetModalFilterCount';

import { searchInfoAtom } from 'src/entities/listing/atoms/searchInfoAtom';

import type { GeographySearch } from 'src/libs/geography';
import {
  calculateCircleArea,
  calculatePolygonArea,
  isAreaSearchGeography,
  isDistanceSearchCircleGeography,
  isDistanceSearchGeography,
  isDistanceSearchIsolineGeography,
} from 'src/libs/geography';
import { useGrowthBookFeature } from 'src/libs/growthbook/client';

import { http } from 'src/utils/client/http';
import { getHasActiveSearchFilters } from 'src/utils/getHasActiveSearchFilters';
import {
  activeSearchSettingsDefault,
  getActiveSearchAlertMessage,
} from '../utils';

import { useRealEstateSearchParams } from 'src/views/ListingSearch/hooks/useRealEstateSearchParams';

const calculateGeometricSearchArea = (geography: GeographySearch) => {
  if (
    isAreaSearchGeography(geography) ||
    isDistanceSearchIsolineGeography(geography)
  ) {
    return Math.round(calculatePolygonArea(geography.value.points));
  }

  if (isDistanceSearchCircleGeography(geography)) {
    return Math.round(calculateCircleArea(geography.value.radius));
  }

  return null;
};

const useGeographySurfaceQuery = (
  isQueryEnabled: boolean,
  params: GeographySurfaceParams
) => {
  return useSuspenseQuery({
    queryKey: ['geography-surface', params, isQueryEnabled],
    queryFn: () => {
      if (!isQueryEnabled) return null;

      return http
        .get('/api-next/geography/surface', { searchParams: params })
        .json<GeographySurfaceResponse>();
    },
  });
};

export const useActiveSearch = (url: string) => {
  const { trans } = useTranslations();
  const geography = useAtomicStateAtomValue(geographySearchAtom);
  const searchInfo = useAtomValue(searchInfoAtom);

  const activeSearchSettings = useGrowthBookFeature(
    'active_search_settings',
    activeSearchSettingsDefault
  );
  const { counters } = useUserAdditionalData();
  const activeSearches = counters?.savedSearchesContactsEnabled ?? 0;

  const {
    idComune,
    idMacroarea,
    idMZona,
    idQuartiere,
    fkMetro,
    fkMetroStation,
    vrt,
    raggio,
    ...searchParams
  } = useRealEstateSearchParams();

  const params: GeographySurfaceParams = {
    'city-id': idComune,
    'macro-area-id': idMacroarea,
    'macrozone-id': idMZona,
    'microzone-id': idQuartiere,
    'metro-id': fkMetro,
    'metro-station-id': fkMetroStation,
  };

  const {
    maxSavedSearches,
    visibility: { searchResultsThreshold, maxSearchAreaSqm, requiredFilters },
    defaultValue: { selectedFiltersThreshold },
  } = activeSearchSettings;

  const isSearchByPolygonOrRadius = geography
    ? isDistanceSearchGeography(geography) || isAreaSearchGeography(geography)
    : false;

  const isActiveSearchLimitExceeded = activeSearches >= maxSavedSearches;

  const isQueryEnabled =
    Boolean(idComune) &&
    !isActiveSearchLimitExceeded &&
    !isSearchByPolygonOrRadius;

  const { data } = useGeographySurfaceQuery(isQueryEnabled, params);

  const areaSqm = useMemo(() => {
    if (data) return data.surface;
    if (geography) return calculateGeometricSearchArea(geography);

    return null;
  }, [data, geography]);

  const isGeographySearchValid = Boolean(idComune) || isSearchByPolygonOrRadius;

  const resultCount = searchInfo?.resultsCount || 0;
  const isResultsCountBelowThreshold = resultCount <= searchResultsThreshold;

  const isAllRequiredFiltersSet = getHasActiveSearchFilters(
    searchParams,
    requiredFilters
  );

  const isSearchAreaBelowThreshold = areaSqm
    ? areaSqm < maxSearchAreaSqm
    : false;

  const alertMessage = getActiveSearchAlertMessage({
    trans,
    isAreaTooLarge: !isSearchAreaBelowThreshold,
    isResultsCountExceeded: !isResultsCountBelowThreshold,
    isFilterRequiredMissing: !isAllRequiredFiltersSet,
    isGeographySearchInvalid: !isGeographySearchValid,
    isActiveSearchLimitExceeded,
    activeSearches,
    url,
  });

  const flagContactsEnabled = useGetFlagContactsEnabled({
    isResultsCountBelowThreshold,
    isAllRequiredFiltersSet,
    isGeographySearchValid,
    isSearchAreaBelowThreshold,
    selectedFiltersThreshold,
    isActiveSearchLimitExceeded,
  });

  return {
    alertMessage,
    areaSqm,
    flagContactsEnabled,
    activeSearches,
    maxSavedSearches,
  };
};

interface UseGetFlagContactsEnabledParams {
  isResultsCountBelowThreshold: boolean;
  isAllRequiredFiltersSet: boolean;
  isGeographySearchValid: boolean;
  isSearchAreaBelowThreshold: boolean;
  selectedFiltersThreshold: number;
  isActiveSearchLimitExceeded: boolean;
}

const useGetFlagContactsEnabled = ({
  isResultsCountBelowThreshold,
  isAllRequiredFiltersSet,
  isGeographySearchValid,
  isSearchAreaBelowThreshold,
  selectedFiltersThreshold,
  isActiveSearchLimitExceeded,
}: UseGetFlagContactsEnabledParams) => {
  const modalFiltersCount = useGetModalFilterCount();

  if (isActiveSearchLimitExceeded) return false;

  if (
    isResultsCountBelowThreshold &&
    isAllRequiredFiltersSet &&
    isGeographySearchValid &&
    isSearchAreaBelowThreshold
  ) {
    // We  need to exclude 'contract' and 'typology' based on the product specification.
    // ref: https://indomio.atlassian.net/browse/PROD-2030
    const excludedFilters = 2;
    const numbersOfFiltersSelected = modalFiltersCount - excludedFilters;

    return numbersOfFiltersSelected >= selectedFiltersThreshold;
  }

  return undefined;
};
