import { useCallback } from 'react';
import { useAuthContext } from '@indomita-react/auth-provider';
import { getCommonTrackingData } from '@indomita-react/segment';
import { useAtomValue } from 'jotai';

import { useAtomicStateAtomValue } from 'src/atoms/atomic-state';

import { geographySearchAtom } from 'src/components/ReactGeographySearch/atoms';

import { searchInfoAtom } from 'src/entities/listing/atoms/searchInfoAtom';
import {
  useIsMapExpanded,
  useIsMapShown,
} from 'src/entities/search/components/SearchLayout';

import type { AlertType } from './useSaveSearchForm';

import type { GeographySearch } from 'src/libs/geography';

import { useSendGA4Event } from 'src/tracking/ga/hooks/useSendGAEvent';
import { useTrack } from 'src/tracking/hooks/useTrack';
import { formatGeographyParams, formatSearchParams } from 'src/tracking/utils';
import { EventName, GATrackingEvent } from 'src/tracking/utils/enums';

import type { SearchParams } from 'src/types/search';

import { useListingSearchParams } from 'src/views/ListingSearch/hooks/useListingSearchParams';

export const alertTypeGA4Map = {
  Daily: 'giornalieri',
  Instant: 'immediati',
  None: 'nessuno',
};

export const useSaveSearchTracking = () => {
  const track = useTrack();

  const geographyData = useAtomicStateAtomValue(geographySearchAtom);
  const searchParams = useListingSearchParams();
  const searchInfo = useAtomValue(searchInfoAtom);

  const { user } = useAuthContext();
  const sendGA4Event = useSendGA4Event();
  const isMapExpanded = useIsMapExpanded();
  const isMapShown = useIsMapShown();

  const trackSearchSaved = useCallback(
    (trackingData: {
      searchParams: SearchParams;
      geographyData: GeographySearch | null;
      resultCount: Nullable<number>;
      alreadySaved: boolean;
      searchId: string;
      alertType?: AlertType;
      action: 'saveFromExisting' | 'update' | 'save';
      fromConfirmationDialogAction: boolean;
      saveSearchTracking: Record<string, unknown>;
      activeSearch?: boolean;
    }) => {
      const {
        searchParams,
        geographyData,
        alreadySaved,
        resultCount,
        alertType,
        action,
        saveSearchTracking,
        activeSearch,
        searchId,
        fromConfirmationDialogAction,
      } = trackingData;

      const entryPointPrefix = fromConfirmationDialogAction
        ? 'Modale Avviso '
        : '';

      const entryPoint = `${entryPointPrefix}${
        isMapExpanded || isMapShown ? 'Mappa' : 'Lista'
      }`;

      const segmentTrackingData = {
        'Is User Logged': Boolean(user),
        'Is Already Saved': alreadySaved,
        'Number of Results Returned': resultCount,
        'Alert Type': alertType,
        'Entry Point': entryPoint,
        'Active Search': activeSearch,
        ...formatGeographyParams({ searchParams, geographyData }),
        ...formatSearchParams(searchParams),
        'Search ID': searchId,
      };

      switch (action) {
        case 'saveFromExisting': {
          //GTM will be deprecated so we do not need to use sendEventToGTM

          sendGA4Event(GATrackingEvent.SAVE_SEARCH_UPDATED, {
            search_updated_status: 'new',
            ...saveSearchTracking,
          });

          track(EventName.SEARCH_SAVED, segmentTrackingData);
          break;
        }

        case 'update': {
          sendGA4Event(GATrackingEvent.SAVE_SEARCH_UPDATED, {
            search_updated_status: 'updated',
            ...saveSearchTracking,
          });

          track(EventName.SEARCH_UPDATED, segmentTrackingData);
          break;
        }

        case 'save': {
          sendGA4Event(GATrackingEvent.SAVE_SEARCH_CONFIRMED, {
            ...saveSearchTracking,
          });
          track(EventName.SEARCH_SAVED, segmentTrackingData);
        }
      }
    },
    [isMapExpanded, isMapShown, sendGA4Event, track, user]
  );

  const trackTrovakasaFormSent = useCallback(
    (alertType?: 'Daily' | 'Instant' | 'None') => {
      track(EventName.TROVAKASA_FORM_SENT, {
        ...formatGeographyParams({ searchParams, geographyData }),
        ...formatSearchParams(searchParams),
        'Entry Point': getCommonTrackingData()['Entry Point'],
        'Alert Type': alertType,
        'Number Of Results Returned': searchInfo?.resultsCount,
      });
    },
    [geographyData, searchInfo?.resultsCount, searchParams, track]
  );

  const trackSearchSaveModalOpened = useCallback(
    (isAutoOpen: boolean, editSearch: boolean) => {
      track(
        editSearch
          ? EventName.SEARCH_UPDATE_MODAL_OPENED
          : EventName.SEARCH_SAVE_MODAL_OPENED,
        {
          ...formatGeographyParams({ searchParams, geographyData }),
          ...formatSearchParams(searchParams),
          'Number Of Results Returned': searchInfo?.resultsCount,
          'Is Auto Opened': editSearch ? undefined : isAutoOpen,
        }
      );
    },
    [geographyData, searchInfo?.resultsCount, searchParams, track]
  );

  return {
    trackSearchSaved,
    trackTrovakasaFormSent,
    trackSearchSaveModalOpened,
  };
};
