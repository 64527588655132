import { useAtomValue } from 'jotai';

import { useAtomicStateAtomValue } from 'src/atoms/atomic-state';

import { geographySearchParamsAtom } from 'src/components/GeographySearch/atoms';
import { mapBoundsAtom } from 'src/components/MapComponent/atoms';
import { hasMapCustomPositionAtom } from 'src/components/RealEstateSearchMap/atoms/hasMapCustomPositionAtom';

import { useDeepEqualMemo } from 'src/hooks/useDeepEqualMemo';
import { formSearchParamsAtom } from 'src/hooks/useRealEstateLiteSearch';

export const useListingSearchParams = () => {
  const geography = useAtomicStateAtomValue(geographySearchParamsAtom);
  const formSearchParams = useAtomicStateAtomValue(formSearchParamsAtom);
  const hasMapCustomPosition = useAtomValue(hasMapCustomPositionAtom);
  const mapBounds = useAtomValue(mapBoundsAtom);

  return useDeepEqualMemo(
    () => ({
      ...geography,
      ...formSearchParams,
      ...(hasMapCustomPosition && mapBounds),
    }),
    [geography, formSearchParams, hasMapCustomPosition, mapBounds]
  );
};
