import md5 from 'blueimp-md5';

import { FormFields } from 'src/components/SearchFilters/config/formConfigTypes';

import { getProductConfig } from 'src/config/product';

import { CATEGORIES } from 'src/constants/typologies';

import type { GrowthBookFeatures } from 'src/libs/growthbook/client';

import type { EntryPointFlagSections } from 'src/types/save-search';
import type { SearchParams } from 'src/types/search';

import type { Trans } from 'src/utils/i18nFormatters';
import { serializeIntoUrl } from 'src/utils/querystring';
import { absoluteURL } from 'src/utils/url';

export const SAVE_SEARCH_AUTO_POPUP_COOKIE = 'IM_PAP_TI';

export const activeSearchSettingsDefault: GrowthBookFeatures['active_search_settings'] =
  {
    maxSavedSearches: 10,
    visibility: {
      searchResultsThreshold: 500,
      requiredFilters: ['prezzoMassimo'],
      maxSearchAreaSqm: 100000000,
    },
    defaultValue: {
      selectedFiltersThreshold: 7,
    },
  };

export const frequencySearchSettingsDefault: GrowthBookFeatures['search_alert_frequency_settings'] =
  {
    realTime: {
      maxSearches: 20,
      maxResults: 2000,
    },
    daily: {
      maxResults: 4000,
    },
  };

const searchParams2SaveSearchParams = {
  [FormFields.CONTRACT]: 'contratto',
  [FormFields.CATEGORY]: 'categoria',
  [FormFields.MIN_PRICE]: 'prezzoMin',
  [FormFields.MAX_PRICE]: 'prezzoMax',
  [FormFields.MIN_SURFACE]: 'supMin',
  [FormFields.MAX_SURFACE]: 'supMax',
  [FormFields.TYPOLOGY]: 'tipologia',
  [FormFields.ROOM_TYPOLOGY]: FormFields.ROOM_TYPOLOGY,
  [FormFields.ROOM_BED_TYPOLOGY]: FormFields.ROOM_BED_TYPOLOGY,
  [FormFields.TENANT_SEX]: FormFields.TENANT_SEX, // CHECK
  [FormFields.TENANT_JOB]: FormFields.TENANT_JOB, // CHECK
  [FormFields.MIN_NUMBER_OF_ROOMS]: 'locMin',
  [FormFields.MAX_NUMBER_OF_ROOMS]: 'locMax',
  [FormFields.BATHROOMS]: FormFields.BATHROOMS,
  [FormFields.STATUS]: 'fkStato',
  [FormFields.PROPERTY_TYPE]: FormFields.PROPERTY_TYPE,
  [FormFields.FLOOR_ZONE]: FormFields.FLOOR_ZONE,
  [FormFields.BOX]: 'boxauto',
  [FormFields.BUILDING_USE]: FormFields.BUILDING_USE,
  [FormFields.HEATING]: 'riscaldamento',
  [FormFields.BALCONY_OR_TERRACE]: FormFields.BALCONY_OR_TERRACE,
  [FormFields.GARDEN]: FormFields.GARDEN,
  [FormFields.ENERGY_RATING]: FormFields.ENERGY_RATING,
  [FormFields.ELEVATOR]: FormFields.ELEVATOR,
  [FormFields.BASEMENT]: FormFields.BASEMENT,
  [FormFields.SWIMMING_POOL]: FormFields.SWIMMING_POOL,
  [FormFields.FURNISHED]: FormFields.FURNISHED,
  [FormFields.EXCLUDE_AUCTIONS]: FormFields.EXCLUDE_AUCTIONS,
  [FormFields.ALARM_SYSTEM]: FormFields.ALARM_SYSTEM,
  [FormFields.ANIMALS]: FormFields.ANIMALS,
  [FormFields.SMOKER]: FormFields.SMOKER,
  [FormFields.VIRTUAL_TOUR]: FormFields.VIRTUAL_TOUR,
  [FormFields.INVESTMENT]: FormFields.INVESTMENT, // CHECK
  [FormFields.LICENSE]: FormFields.LICENSE,
  [FormFields.MIN_BEDROOMS]: FormFields.MIN_BEDROOMS,
  [FormFields.MAX_BEDROOMS]: FormFields.MAX_BEDROOMS,
  [FormFields.LANGUAGE]: FormFields.LANGUAGE,
  idComune: 'comune',
  idProvincia: 'provincia',
  fkMetroStation: 'fkMetroStation',
  idAreaTuristica: 'fkAreaTuristica',
  fkMetro: 'fkMetro',
  indirizzo: 'indirizzo',
};

export const mapParamsToSaveSearchParams = (params) => {
  const saveSearchParams = {};

  Object.keys(params).forEach((param) => {
    if (searchParams2SaveSearchParams[param]) {
      saveSearchParams[searchParams2SaveSearchParams[param]] = params[param];
    }
  });

  if (params['vrt']) {
    saveSearchParams['gm_area'] = JSON.stringify({
      mode: 'polygon',
      area: params['vrt'].split(';').map((val) => val.split(',')),
    });
    saveSearchParams['tipoRicerca'] = 'mappa';
  } else if (params['raggio']) {
    saveSearchParams['gm_area'] = JSON.stringify({
      mode: 'circle',
      area: {
        radius: params['raggio'],
        center: params['centro'].split(';').map((val) => val.split(','))[0],
      },
    });
    saveSearchParams['tipoRicerca'] = 'mappa';
  }

  if (params['idMZona']) {
    saveSearchParams['zona'] = params['idMZona'].join(',');
  }

  if (params['idQuartiere']) {
    saveSearchParams['fkMicrozona'] = params['idQuartiere'].join(',');
  }

  // Mapping exceptions on typologies
  switch (params.idCategoria) {
    case CATEGORIES.STANZE: {
      if (params?.idTipologia?.length > 0) {
        saveSearchParams['categoriaStanza'] = params['idTipologia'][0];
        saveSearchParams['tipologia'] = undefined;
      }

      break;
    }

    case CATEGORIES.NEGOZI_LOCALI_COMMERCIALI: {
      saveSearchParams['tipoNegozioCommerciale'] = params['idTipologia'];
      saveSearchParams['tipologia'] = undefined;
      break;
    }
  }

  return saveSearchParams;
};

export class AttempsError extends Error {
  constructor() {
    super();
    this.name = 'AttempsError';
  }
}

export const computeCanonicalUrl = (searchParams: SearchParams): string => {
  const searchParamsForCanonical: SearchParams = {
    idContratto: searchParams.idContratto,
    idCategoria: searchParams.idCategoria,
    idTipologia: searchParams.idTipologia,
    idProvincia: searchParams.idProvincia,
    idComune: searchParams.idComune,
    idMZona: searchParams.idMZona,
    idQuartiere: searchParams.idQuartiere,
    vrt: searchParams.vrt,
    fkMetroStation: searchParams.fkMetroStation,
    fkMetro: searchParams.fkMetro,
  };
  const url = absoluteURL(
    serializeIntoUrl('/search-list', searchParamsForCanonical)
  );

  return md5(url);
};

type TransParams = (string | number)[];

type Condition = {
  condition: boolean;
  transKey: string;
  transParams?: TransParams;
};

export const getAlertMessage = (trans: Trans, conditions: Condition[]) => {
  for (const { condition, transKey, transParams } of conditions) {
    if (condition) {
      return trans(transKey, {
        params: transParams,
      });
    }
  }

  return null;
};

export const getActiveSearchAlertMessage = ({
  trans,
  isAreaTooLarge,
  isResultsCountExceeded,
  isFilterRequiredMissing,
  isGeographySearchInvalid,
  isActiveSearchLimitExceeded,
  activeSearches,
  url,
}: {
  trans: Trans;
  isAreaTooLarge: boolean;
  isResultsCountExceeded: boolean;
  isFilterRequiredMissing: boolean;
  isGeographySearchInvalid: boolean;
  isActiveSearchLimitExceeded: boolean;
  activeSearches: number;
  url: string;
}) => {
  const activeSearchConditions = [
    {
      condition: isActiveSearchLimitExceeded,
      transKey: 'lbl_receive_contacts_alert',
      transParams: activeSearches
        ? [`<span>${activeSearches}</span>`, `<a href=${url}>`, '</a>']
        : [],
    },
    {
      condition: isFilterRequiredMissing,
      transKey: 'lbl_receive_contacts_max_price_info',
    },
    {
      condition: isAreaTooLarge,
      transKey: 'lbl_receive_contacts_area_info',
    },
    {
      condition: isResultsCountExceeded || isGeographySearchInvalid,
      transKey: 'lbl_receive_contacts_info',
    },
  ];

  return getAlertMessage(trans, activeSearchConditions);
};

export const getSaveSearchSectionUrl = (
  entryPoint: EntryPointFlagSections,
  filter: 'active' | 'realtime'
) => {
  const baseUrl = getProductConfig('user').savedSearchesUrl;

  return absoluteURL(`${baseUrl}?filter=${filter}&entryPoint=${entryPoint}`);
};
