import { useEffect, useState } from 'react';
import { getCookie, setCookie } from '@pepita/lotrash';
import { useRouter } from 'next/router';

import { useCheckCookieConsentChanged } from 'src/components/CookieManager';

import { useAutoOpenSaveSearch } from './hooks/useAutoOpenSaveSearch';

import { computeCanonicalUrl, SAVE_SEARCH_AUTO_POPUP_COOKIE } from '../utils';

import { useListingSearchParams } from 'src/views/ListingSearch/hooks/useListingSearchParams';

import { trackSendModalAction } from '../metrics';
import { SaveSearchDialog } from '../SaveSearchDialog';

const MAX_SAVE_SEARCH_OPEN_TIMES = 3;

export const getAutoSaveCookie = () => {
  try {
    const token = getCookie(SAVE_SEARCH_AUTO_POPUP_COOKIE);
    const decodedToken = token && JSON.parse(atob(token));

    return { num: decodedToken?.num || 0, url: decodedToken?.url || '' };
  } catch {
    return { num: 0, url: '' };
  }
};

export function AutoSaveSearchDialog() {
  function handleClose() {
    setIsOpen(false);
  }

  const [isOpen, setIsOpen] = useState(false);

  const autoOpenSaveSearchModal = useAutoOpenSaveSearch();

  const searchParams = useListingSearchParams();

  // Every time the current url change we have a new search
  const { asPath } = useRouter();

  const cookieConsentChanged = useCheckCookieConsentChanged();

  useEffect(() => {
    if (cookieConsentChanged && autoOpenSaveSearchModal) {
      // Get current cookie
      const { num, url } = getAutoSaveCookie();
      const currentComputedUrl = computeCanonicalUrl(searchParams);

      // Do not render the modal if it has already been opened 3 times,
      // if the current URL is the same as when the modal was last opened,
      if (num >= MAX_SAVE_SEARCH_OPEN_TIMES || url === currentComputedUrl) {
        trackSendModalAction('closed');

        return;
      }

      setIsOpen(true);

      trackSendModalAction('opened');
      setCookie(
        SAVE_SEARCH_AUTO_POPUP_COOKIE,
        btoa(
          JSON.stringify({
            num: num + 1,
            url: computeCanonicalUrl(searchParams),
          })
        ),
        7
      );
    }
  }, [asPath, autoOpenSaveSearchModal, cookieConsentChanged]);

  return isOpen ? (
    <SaveSearchDialog
      editSearch={false}
      onClose={handleClose}
      entryPointPosition="autoOpen"
    />
  ) : null;
}
