import { useAuthContext } from '@indomita-react/auth-provider';
import { useSniperLink } from '@indomita-react/sniper-link';
import { useTranslations } from '@pepita-react/i18n';
import { useAtomValue } from 'jotai';

import type { ProductConfig } from 'src/config/product';
import { getProductConfig } from 'src/config/product';

import { searchInfoAtom } from 'src/entities/listing/atoms/searchInfoAtom';

export const useManageSavedSearchLink = () => {
  const { trans } = useTranslations();
  const { user } = useAuthContext();
  const searchInfo = useAtomValue(searchInfoAtom);

  const userUrls = getProductConfig('user');
  const domainName: ProductConfig['domainName'] =
    getProductConfig('domainName');

  const sniperLink = useSniperLink(searchInfo?.email, domainName);

  const showAction = Boolean(user) || sniperLink;

  const getLink = () => {
    if (!showAction) return undefined;

    if (user) {
      return userUrls.savedSearchesUrl;
    }

    return sniperLink;
  };

  const getLabel = () => {
    if (!showAction) return undefined;

    if (user) {
      return trans('act_manage_alerts');
    }

    return trans('open_mail');
  };

  return {
    showAction,
    link: getLink(),
    label: getLabel(),
  };
};
