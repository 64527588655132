import { Suspense, useEffect } from 'react';
import {
  useAuthContext,
  useUserAdditionalData,
} from '@indomita-react/auth-provider';
import { useTranslations } from '@pepita-react/i18n';
import { useAtomValue } from 'jotai';

import { ErrorBoundary } from 'src/components/ErrorBoundary';
import type { SaveSearchProps } from 'src/components/SaveSearch';
import { useSearchBadges } from 'src/components/SaveSearch/hooks/useSearchBadges';

import { isFeatureEnabled } from 'src/config/features-toggle';

import { searchInfoAtom } from 'src/entities/listing/atoms/searchInfoAtom';

import type { SimpleForm } from 'src/hooks/useSimpleForm';
import { useFrequencySectionData } from '../hooks/useFrequencySection';
import { useLocationPreview } from '../hooks/useLocationPreview';
import { getAlertType } from '../hooks/useSaveSearchForm';
import { alertTypeGA4Map } from '../hooks/useSaveSearchTracking';

import { useGrowthBookFeature } from 'src/libs/growthbook/client';
import { PepitaTextField } from 'src/libs/ui/pepita-text-field';

import { useSearchListTrackingData } from 'src/tracking/ga/hooks/useSearchListTrackingData';
import { useSendGA4Event } from 'src/tracking/ga/hooks/useSendGAEvent';
import { getBooleanString } from 'src/tracking/utils';
import { GATrackingEvent } from 'src/tracking/utils/enums';

import type { SaveSearchData } from 'src/types/save-search';

import { activeSearchSettingsDefault, getSaveSearchSectionUrl } from '../utils';

import { ActiveSearchSection } from '../ActiveSearchSection';
import { FrequencySection } from '../FrequencySection';
import { ReceiveContactsSection } from '../ReceiveContactsSection';
import { SearchSummary } from '../SearchSummary';
import { SkeletonReceiveContactsSection } from '../SkeletonReceiveContactsSection';

import css from './styles.module.scss';

export function ContentDialog({
  editSearch,
  form,
  entryPointPosition,
}: {
  editSearch?: boolean;
  form: SimpleForm<SaveSearchData>;
  entryPointPosition: SaveSearchProps['position'];
}) {
  const { trans } = useTranslations();
  const { user } = useAuthContext();
  const { counters } = useUserAdditionalData();

  const searchInfo = useAtomValue(searchInfoAtom);
  const searchParams = form.data.searchParams;
  const locationPreview = useLocationPreview(searchParams);
  const badges = useSearchBadges();
  const sendGA4Event = useSendGA4Event();
  const searchListTracking = useSearchListTrackingData();

  const activeSearchSettings = useGrowthBookFeature(
    'active_search_settings',
    activeSearchSettingsDefault
  );

  const {
    counterHeader,
    realTimeErrorMessage,
    dailyErrorMessage,
    isRealTimeNotificationFlagBlocked,
    isDailyNotificationFlagBlocked,
  } = useFrequencySectionData({
    realTimeAlerts: counters?.savedSearchesRealTimeAlert ?? 0,
    listingsResultsCount: searchInfo?.resultsCount ?? 0,
    dailyResultsCountTransKey: 'lbl_save_daily_alert_results_listings_limit',
    realTimeResultsCountTransKey:
      'lbl_save_realtime_alert_results_listings_limit',
    url: getSaveSearchSectionUrl(
      editSearch ? 'update-search' : 'save-search',
      'realtime'
    ),
  });

  useEffect(() => {
    if (searchParams) {
      const searchImpressionTracking = searchListTracking(entryPointPosition);
      // TODO: we should move this inside tracking file
      const alertType = getAlertType(
        Boolean(form.data.flagNotificationDaily),
        Boolean(form.data.flagNotificationRealtime)
      );

      sendGA4Event(GATrackingEvent.SAVE_SEARCH_IMPRESSION, {
        ...searchImpressionTracking,
        is_active: getBooleanString(form.data.flagContactsEnabled),
        alert_type: alertType ? alertTypeGA4Map[alertType] : undefined,
      });
    }
    // This tracking should be sent only once, because of that, we don't use `form.data` flags in dependencies to avoid triggering tracking when changed by the user.
  }, [searchParams, sendGA4Event]);

  return (
    <div className={'nd-form'} data-cy="save-search-dialog">
      {editSearch && (
        <p>
          <span className="nd-typography--headingSmall">
            {`${trans('save_search_box_title_update')}. `}
          </span>
          {trans('update_search_description')}
        </p>
      )}

      <div className={css['in-saveSearchContentDialog__box']}>
        <PepitaTextField
          name="searchName"
          floatingLabel
          label={trans('lbl_save_search_dialog_name', { capitalize: true })}
          placeholder={searchInfo?.searchName}
          status={form.getErrorMessage('searchName') ? 'error' : undefined}
          statusMessage={form.getErrorMessage('searchName')}
          defaultValue={form.data.searchName}
          onChange={form.handleTextInputChange('searchName')}
          iconAlignment="right"
          icon={{
            name: 'pencil',
          }}
          maxLength={75}
        />
        <SearchSummary locationPreview={locationPreview} badges={badges} />
      </div>

      {isFeatureEnabled('REALTIME_NOTIFICATIONS_ENABLED') && (
        <FrequencySection
          value={{
            flagNotificationDaily: Boolean(form.data.flagNotificationDaily),
            flagNotificationRealtime: Boolean(
              form.data.flagNotificationRealtime
            ),
          }}
          handleChange={form.handleChange}
          counterHeader={counterHeader}
          realTimeErrorMessage={realTimeErrorMessage}
          isRealTimeNotificationFlagBlocked={isRealTimeNotificationFlagBlocked}
          isDailyNotificationFlagBlocked={isDailyNotificationFlagBlocked}
          dailyErrorMessage={dailyErrorMessage}
        />
      )}

      {isFeatureEnabled('ACTIVE_SEARCH') && user && (
        <ErrorBoundary
          FallbackComponent={
            <ReceiveContactsSection
              value={{
                flagContactsEnabled: undefined,
              }}
              handleChange={form.handleChange}
              showFlagContactsField
              disabledSwitch
              errorMessage={null}
              activeSearches={counters?.savedSearchesContactsEnabled ?? 0}
              maxSavedSearches={activeSearchSettings.maxSavedSearches}
            />
          }
        >
          <Suspense fallback={<SkeletonReceiveContactsSection />}>
            <ActiveSearchSection
              handleChange={form.handleChange}
              toggleValue={form.data.flagContactsEnabled}
              editSearch={Boolean(editSearch)}
            />
          </Suspense>
        </ErrorBoundary>
      )}
    </div>
  );
}
