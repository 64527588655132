import { useCallback } from 'react';
import { useRouter } from 'next/router';

import { useAtomicStateAtomValue } from 'src/atoms/atomic-state';

import { getGeographyEntityLabel } from 'src/components/GeographySearch/utils';
import { geographySearchAtom } from 'src/components/ReactGeographySearch/atoms';

import {
  getBooleanString,
  getCategoryByCategoryId,
  getConditionByFilter,
  getContractType,
  getFloorByFilter,
  getGarageByFilter,
  getGardenByFilter,
  getHeatingByFilter,
  getOtherFeaturesByFilter,
  getTypologiesByFilter,
  isAuction,
} from 'src/tracking/utils';

import { ENTITY_TYPE } from 'src/types/geography';

import { compactObject } from 'src/utils/object';

import { useListingSearchParams } from 'src/views/ListingSearch/hooks/useListingSearchParams';

export const useSearchListTrackingData = (realEstateIds?: number[]) => {
  const geography = useAtomicStateAtomValue(geographySearchAtom);
  const { locale } = useRouter();
  const searchParams = useListingSearchParams();
  const {
    prezzoMinimo: priceMin,
    prezzoMassimo: priceMax,
    idTipologia: typologyIdV1,
    idContratto: contractId,
    idCategoria: categoryId,
    lusso: properties_is_luxury,
    localiMassimo: properties_rooms_max,
    localiMinimo: properties_rooms_min,
    bagni: properties_baths,
    superficieMassima: properties_surface_max,
    superficieMinima: properties_surface_min,
    ascensore: properties_has_elevator,
    riscaldamenti,
    fasciaPiano,
    stato,
    giardino,
    boxAuto,
  } = searchParams;
  const otherFeaturesFilters = getOtherFeaturesByFilter(searchParams);

  const searchTrackingData = useCallback(
    (entryPointPosition?: 'box' | 'searchbar' | 'autoOpen') =>
      compactObject({
        properties_ids: realEstateIds?.join('|'),
        properties_contract: getContractType(contractId),
        properties_type: getTypologiesByFilter(categoryId, typologyIdV1),
        properties_price_min: priceMin,
        properties_price_max: priceMax,
        properties_country:
          getGeographyEntityLabel(geography, ENTITY_TYPE.country) || undefined,
        properties_region:
          getGeographyEntityLabel(geography, ENTITY_TYPE.region) || undefined,
        properties_province:
          getGeographyEntityLabel(geography, ENTITY_TYPE.province) || undefined,
        properties_city:
          getGeographyEntityLabel(geography, ENTITY_TYPE.city) || undefined,
        properties_macrozone:
          getGeographyEntityLabel(geography, ENTITY_TYPE.cityZone) || undefined,
        properties_microzone:
          getGeographyEntityLabel(geography, ENTITY_TYPE.microzone) ||
          undefined,
        properties_touristicarea:
          getGeographyEntityLabel(geography, ENTITY_TYPE.touristicArea) ||
          undefined,
        properties_is_luxury: getBooleanString(properties_is_luxury),
        properties_rooms_min,
        properties_rooms_max,
        properties_baths,
        properties_surface_min,
        properties_surface_max,
        properties_floor: getFloorByFilter(fasciaPiano),
        properties_has_elevator: getBooleanString(properties_has_elevator),
        properties_state: getConditionByFilter(stato),
        properties_heating: getHeatingByFilter(riscaldamenti),
        properties_otherfeatures:
          otherFeaturesFilters && otherFeaturesFilters.length !== 0
            ? otherFeaturesFilters.join('|')
            : undefined,
        language: locale,
        view_type: entryPointPosition,
        properties_category: getCategoryByCategoryId(categoryId),
        properties_garage: getGarageByFilter(boxAuto),
        properties_garden: getGardenByFilter(giardino),
        properties_is_auction: getBooleanString(isAuction(contractId)),
      }),
    [
      realEstateIds,
      priceMin,
      priceMax,
      categoryId,
      typologyIdV1,
      contractId,
      geography,
      properties_is_luxury,
      properties_rooms_min,
      properties_rooms_max,
      properties_baths,
      properties_surface_min,
      properties_surface_max,
      fasciaPiano,
      properties_has_elevator,
      stato,
      riscaldamenti,
      otherFeaturesFilters,
      locale,
      boxAuto,
      giardino,
    ]
  );

  return searchTrackingData;
};
