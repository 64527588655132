import { useEffect, useMemo, useState } from 'react';
import type { UserData } from '@indomita-react/auth-provider';
import {
  addUserAdditionalData,
  useAuthContext,
  useUserAdditionalData,
} from '@indomita-react/auth-provider';
import { useTranslations } from '@pepita-react/i18n';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';

import { useAtomicStateAtomValue } from 'src/atoms/atomic-state';

import { authModalAtom } from 'src/components/AuthModal/atoms/authModalAtom';
import { geographySearchAtom } from 'src/components/ReactGeographySearch/atoms';
import type { SaveSearchProps } from 'src/components/SaveSearch';

import { isFeatureEnabled } from 'src/config/features-toggle';
import { getProductConfig } from 'src/config/product';

import { searchInfoAtom } from 'src/entities/listing/atoms/searchInfoAtom';

import { useSimpleForm } from 'src/hooks/useSimpleForm';
import { useSaveSearchDialogSchema } from './useSaveSearchDialogSchema';
import {
  alertTypeGA4Map,
  useSaveSearchTracking,
} from './useSaveSearchTracking';

import { useGrowthBookFeature } from 'src/libs/growthbook/client';

import { useSearchListTrackingData } from 'src/tracking/ga/hooks/useSearchListTrackingData';
import { getBooleanString } from 'src/tracking/utils';

import { RegisterType } from 'src/types/auth';
import type {
  SaveSearchActionType,
  SaveSearchData,
} from 'src/types/save-search';
import type { SearchInfo } from 'src/types/search';

import { AttempsError, frequencySearchSettingsDefault } from '../utils';

import { useRealEstateSearchParams } from 'src/views/ListingSearch/hooks/useRealEstateSearchParams';

import { postSaveSearch } from '../api';

export type AlertType = 'Daily' | 'Instant' | 'None';

export const getAlertType = (
  dailyNotification: boolean,
  realtimeNotification: boolean
): Optional<AlertType> => {
  if (!isFeatureEnabled('REALTIME_NOTIFICATIONS_ENABLED')) {
    return undefined;
  }

  if (dailyNotification) {
    return 'Daily';
  }

  if (realtimeNotification) {
    return 'Instant';
  }

  return 'None';
};

const initUserRelatedData = (
  user: Nullable<UserData>,
  flagNotificationRealtime: boolean,
  flagNotificationDaily: boolean,
  flagContactsEnabled?: boolean
) => {
  const userRelatedData = {
    flagContactsEnabled,
    flagNotificationRealtime,
    flagNotificationDaily,
  } as SaveSearchData;

  if (user) {
    userRelatedData.email = user.email;
    userRelatedData.nome = user.name;
    userRelatedData.cognome = user.surname;
    userRelatedData.telefono = user.phone ?? undefined;
  } else {
    userRelatedData.email = '';
    userRelatedData.nome = undefined;
    userRelatedData.cognome = undefined;
    userRelatedData.telefono = undefined;
  }

  return userRelatedData;
};

const useInitialFrequencyFlags = () => {
  const { user } = useAuthContext();
  const { counters } = useUserAdditionalData();
  const searchInfo = useAtomValue(searchInfoAtom);
  const resultsCount = searchInfo?.resultsCount ?? 0;

  const frequencySearch = useGrowthBookFeature(
    'search_alert_frequency_settings',
    frequencySearchSettingsDefault
  );

  const realTimeAlerts = counters?.savedSearchesRealTimeAlert ?? 0;

  return useMemo(() => {
    if (!user) {
      return {
        flagNotificationRealtime: false,
        flagNotificationDaily: true, //Anonymous users only receive daily notifications
      };
    }

    const isRealTimeAlertWithinSaveSearchLimit =
      realTimeAlerts < frequencySearch.realTime.maxSearches;

    const isRealTimeAlertWithinResultsCountLimit =
      resultsCount < frequencySearch.realTime.maxResults;

    const isResultsCountWithinDailyLimit =
      resultsCount < frequencySearch.daily.maxResults;

    const flagNotificationRealtime =
      isRealTimeAlertWithinSaveSearchLimit &&
      isRealTimeAlertWithinResultsCountLimit;

    const flagNotificationDaily = flagNotificationRealtime
      ? false
      : isResultsCountWithinDailyLimit;

    return {
      flagNotificationRealtime,
      flagNotificationDaily,
    };
  }, [
    frequencySearch.daily.maxResults,
    frequencySearch.realTime.maxResults,
    frequencySearch.realTime.maxSearches,
    realTimeAlerts,
    resultsCount,
    user,
  ]);
};

const askToAgenciesDisabled = (
  saveSearchData: SaveSearchData,
  searchInfo: SearchInfo
) => {
  // If the new  new save search dialog is enabled, the modal "Ask to Agencies" is always be disabled.
  //https://indomio.atlassian.net/browse/DEV-8869
  if (isFeatureEnabled('ACTIVE_SEARCH')) {
    return true;
  }

  // Show modal "Ask to Agencies" only when a new search is saved,
  // avoiding searches for provinces and international searches.
  const isNewSearchForAgencies =
    saveSearchData.searchParams.idProvincia &&
    !saveSearchData.searchParams.idComune;

  // Avoid showing when international searches are not enabled,
  // and the search is for a country different from the one configured in the product.
  const isInternationalSearch =
    !isFeatureEnabled('INTERNATIONAL_SEARCH_ASK_TO_AGENCIES_ENABLED') &&
    saveSearchData.searchParams.idNazione &&
    saveSearchData.searchParams.idNazione !== getProductConfig('countryCode');

  // Avoid opening for city searches with more than 1500 results.
  const isCitySearchWithMoreThan1500Results =
    saveSearchData.searchParams.idComune &&
    !(
      saveSearchData.searchParams.idMZona ||
      saveSearchData.searchParams.idQuartiere ||
      saveSearchData.searchParams.fkMetro ||
      saveSearchData.searchParams.fkMetroStation
    ) &&
    searchInfo.resultsCount &&
    searchInfo.resultsCount > 1500;

  return Boolean(
    isNewSearchForAgencies ||
      isInternationalSearch ||
      isCitySearchWithMoreThan1500Results
  );
};

export const useSaveSearchForm = ({
  editSearch,
  onClose,
  onSuccess,
  entryPointPosition,
}: {
  editSearch?: boolean;
  onClose: () => void;
  onSuccess?: () => void;
  entryPointPosition: SaveSearchProps['position'];
}) => {
  const { trans } = useTranslations();
  const { user } = useAuthContext();
  const { counters } = useUserAdditionalData();

  const geography = useAtomicStateAtomValue(geographySearchAtom);
  const realEstateSearchParams = useRealEstateSearchParams();
  const schema = useSaveSearchDialogSchema(editSearch);
  const [searchInfo, setSearchInfo] = useAtom(searchInfoAtom);
  const { trackSearchSaved } = useSaveSearchTracking();
  const resultsCount = searchInfo?.resultsCount ?? null;
  const { flagNotificationDaily, flagNotificationRealtime } =
    useInitialFrequencyFlags();

  const [initialData, setInitialData] = useState<SaveSearchData>({
    ...initUserRelatedData(
      user,
      flagNotificationRealtime,
      flagNotificationDaily
    ),
    searchParams: realEstateSearchParams,
  });

  useEffect(() => {
    setInitialData((oldState) => ({
      ...oldState,
      ...initUserRelatedData(
        user,
        flagNotificationRealtime,
        flagNotificationDaily
      ),
    }));
  }, [
    entryPointPosition,
    flagNotificationDaily,
    flagNotificationRealtime,
    resultsCount,
    user,
  ]);

  const [askToAgencies, setAskToAgencies] = useState<Nullable<boolean>>(null);
  const [searchID, setSearchID] = useState(searchInfo?.searchId);

  const setAuthModal = useSetAtom(authModalAtom);

  const [alertInfoAuth, setAlertInfoAuth] = useState<boolean>(false);
  const saveSearchFormTracking = useSearchListTrackingData();

  const form = useSimpleForm<
    SaveSearchData,
    {
      searchId: string;
      userRegisterType: string;
      savedSearchesRealTimeAlert: number;
      savedSearchesContactsEnabled: number;
    }
  >({
    schema,
    initialData,
    onSubmit: async (
      data,
      extra: {
        action: SaveSearchActionType;
        fromConfirmationDialogAction?: boolean;
      }
    ) => {
      const { action } = extra;
      const { minLat, maxLat, minLng, maxLng } = data.searchParams;
      const mapViewportPolygon = `${minLat},${minLng};${minLat},${maxLng};${maxLat},${maxLng};${maxLat},${minLng};${minLat},${minLng}`;

      // Without geography we need to create a custom one, based on the map viewport.
      const searchParams =
        geography !== null
          ? data.searchParams
          : { ...data.searchParams, vrt: mapViewportPolygon };

      const postData: SaveSearchData = {
        ...data,
        searchParams,
        privacy: data.privacy || Boolean(user),
        nome: user?.name,
        cognome: user?.surname,
        telefono: user?.phone ?? undefined,
        email: user?.email || data.email,
        source: 'L-AN-G',
        action,
        searchID,
      };

      if (data.searchName && data.searchName !== '') {
        postData['searchName'] = data.searchName;
      }

      return postSaveSearch(postData).then(
        ({
          searchId,
          userRegisterType,
          savedSearchesContactsEnabled,
          savedSearchesRealTimeAlert,
        }) => {
          if (searchId) {
            setSearchID(searchId);
            setSearchInfo((searchInfo) => ({
              ...searchInfo,
              searchId,
              isEditing: false,
              isUpdated: action === 'update',
              email: postData.email,
              resultsCount: searchInfo?.resultsCount ?? null,
              searchName: searchInfo?.searchName ?? '',
              showTooltip: Boolean(searchInfo?.showTooltip),
              isFetching: Boolean(searchInfo?.isFetching),
              token: searchInfo?.token ?? null,
            }));
          }

          return {
            searchId,
            userRegisterType,
            savedSearchesContactsEnabled,
            savedSearchesRealTimeAlert,
          };
        }
      );
    },
    onSuccess: (
      saveSearchData,
      submitResponse,
      extra: {
        action: SaveSearchActionType;
        fromConfirmationDialogAction: boolean;
      }
    ) => {
      const { action, fromConfirmationDialogAction } = extra;

      onSuccess?.();
      const {
        flagNotificationDaily,
        flagNotificationRealtime,
        flagContactsEnabled,
      } = saveSearchData;

      const alertType = getAlertType(
        flagNotificationDaily || false,
        flagNotificationRealtime || false
      );

      const isActiveSearch = isFeatureEnabled('ACTIVE_SEARCH')
        ? flagContactsEnabled
        : undefined;

      const saveSearchTracking = {
        ...saveSearchFormTracking(entryPointPosition),
      };

      if (user) {
        addUserAdditionalData({
          counters: {
            ...counters,
            savedSearchesContactsEnabled:
              submitResponse.savedSearchesContactsEnabled,
            savedSearchesRealTimeAlert:
              submitResponse.savedSearchesRealTimeAlert,
          },
        });
      }

      switch (action) {
        case 'saveFromExisting': {
          if (searchInfo && askToAgenciesDisabled(saveSearchData, searchInfo)) {
            onClose();
          } else {
            setAskToAgencies(true);
          }

          trackSearchSaved({
            searchParams: realEstateSearchParams,
            geographyData: geography,
            resultCount: resultsCount,
            alreadySaved: false,
            alertType,
            action,
            fromConfirmationDialogAction,
            saveSearchTracking,
            activeSearch: isActiveSearch,
            searchId: submitResponse.searchId,
          });

          return trans('toast_search_saved');
        }

        case 'update': {
          onClose();
          trackSearchSaved({
            searchParams: realEstateSearchParams,
            geographyData: geography,
            resultCount: resultsCount,
            alreadySaved: true,
            alertType,
            action,
            fromConfirmationDialogAction,
            saveSearchTracking,
            activeSearch: isActiveSearch,
            searchId: submitResponse.searchId,
          });

          return trans('toast_search_updated');
        }

        case 'save': {
          trackSearchSaved({
            searchParams: realEstateSearchParams,
            geographyData: geography,
            resultCount: resultsCount,
            alreadySaved: false,
            alertType,
            action,
            fromConfirmationDialogAction,
            saveSearchTracking: {
              ...saveSearchTracking,
              is_active: getBooleanString(saveSearchData.flagContactsEnabled),
              alert_type: alertType ? alertTypeGA4Map[alertType] : undefined,
            },
            activeSearch: isActiveSearch,
            searchId: submitResponse.searchId,
          });

          /* Checking if the user is not authenticated and if the feature is enabled. */
          const showAuthModals =
            !user && isFeatureEnabled('AUTH_POST_SAVE_SEARCH');

          if (searchInfo && askToAgenciesDisabled(saveSearchData, searchInfo)) {
            if (showAuthModals) {
              if (
                submitResponse.userRegisterType === RegisterType.UNREGISTERED
              ) {
                setAlertInfoAuth(true);
              } else {
                setAuthModal({
                  open: true,
                  data: {
                    email: saveSearchData.email,
                    alertMessage: trans('toast_search_saved'),
                    additionalTrackingData: {
                      'Entry Point': 'Modale Salva Ricerca',
                    },
                  },
                  onClose: () => {
                    setAuthModal({ open: false });
                    onClose();
                  },
                  onSuccess: () => {
                    setAuthModal({ open: false });
                    onClose();
                  },
                  showAdvantageInfo: true,
                });
              }
            } else {
              onClose();
            }
          } else {
            if (showAuthModals) {
              if (
                submitResponse.userRegisterType === RegisterType.UNREGISTERED
              ) {
                setAlertInfoAuth(true);
              } else {
                setAuthModal({
                  open: true,
                  data: {
                    email: saveSearchData.email,
                    alertMessage: trans('toast_search_saved'),
                    additionalTrackingData: {
                      'Entry Point': 'Modale Salva Ricerca',
                    },
                  },
                  onClose: () => setAuthModal({ open: false }),
                  onSuccess: () => setAuthModal({ open: false }),
                  showAdvantageInfo: true,
                });
              }
            }

            setAskToAgencies(true);
          }

          //disable snackbar message if showAuthModals if true
          return !showAuthModals ? trans('toast_search_saved') : '';
        }
      }
    },
    onError: (err) => {
      if (err instanceof AttempsError) {
        return trans('save_search_alert_attemps_error');
      }

      return trans('lbl_generic_error');
    },
  });

  return {
    askToAgencies,
    setAskToAgencies,
    searchID,
    form,
    alertInfoAuth,
    setAlertInfoAuth,
  };
};
