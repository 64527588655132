import type { BaseEntity } from '@indomita-website/map-polygons';

import { useAtomicStateAtomValue } from 'src/atoms/atomic-state';

import { ENTITY_TYPE } from 'src/components/GeographySearch/utils';
import { geographySearchAtom } from 'src/components/ReactGeographySearch/atoms';

import type { GeographySearch } from 'src/libs/geography';

import type { LocationPreviewType } from 'src/types/location-preview';
import type { SearchParams } from 'src/types/search';

import {
  getAlternateLocationPreviewUrl,
  getCityLocationPreviewUrl,
  getCityZoneLocationPreviewUrl,
  getDrawingLocationPreviewUrl,
} from 'src/utils/locationPreview';

const getPlaceLocationPreview = (entities: BaseEntity[]): Nullable<string> => {
  if (
    entities[0]?.type === ENTITY_TYPE.metro ||
    entities[0]?.type === ENTITY_TYPE.metroLine
  ) {
    return null;
  }

  if (entities[0]?.type === ENTITY_TYPE.city) {
    // If first (and only) value is a city we do not have to search
    // for microzones
    return getCityLocationPreviewUrl(entities[0].id, '328x109', '3');
  }

  const macrozoneIDs: string[] = [];

  entities.forEach((entity) => {
    if (entity.type === ENTITY_TYPE.cityZone) {
      macrozoneIDs.push(entity.id);
    } else if (entity.type === ENTITY_TYPE.microzone) {
      macrozoneIDs.push(entity.parents[0].id);
    }
  });

  const cityId = entities[0].parents.find(
    (parent) => parent.type === ENTITY_TYPE.city
  )?.id;

  return getCityZoneLocationPreviewUrl(cityId, '328x109', '3', [
    ...new Set(macrozoneIDs),
  ]);
};

export const getDrawingLocationPreview = (
  geography: GeographySearch
): Nullable<string> => {
  return getDrawingLocationPreviewUrl(geography, 328, 109);
};

const getMainImage = (
  geography: GeographySearch | null,
  searchParams: Optional<SearchParams>
) => {
  // This casting is needed because of the too much generic type of SearchParams.
  const { maxLat, maxLng, minLat, minLng } = searchParams as {
    maxLat: Optional<number>;
    maxLng: Optional<number>;
    minLat: Optional<number>;
    minLng: Optional<number>;
  };

  if (geography === null && maxLat && maxLng && minLat && minLng) {
    const geography = {
      searchType: 'polygon' as const,
      value: {
        points: [
          [minLat, minLng],
          [minLat, maxLng],
          [maxLat, maxLng],
          [maxLat, minLng],
          [minLat, minLng],
        ],
      },
    };

    return getDrawingLocationPreview(geography);
  }

  if (geography === null) return;

  if (geography.searchType === 'place') {
    return getPlaceLocationPreview(geography.value);
  }

  return getDrawingLocationPreview(geography);
};

export const useLocationPreview = (
  searchParams: Optional<SearchParams>
): LocationPreviewType => {
  const geography = useAtomicStateAtomValue(geographySearchAtom);

  return {
    main:
      getMainImage(geography, searchParams) || getAlternateLocationPreviewUrl(),
    alternate: getAlternateLocationPreviewUrl(),
  };
};
