import { createAtomicStateDerivedAtom } from 'src/atoms/atomic-state';

import { formStateAtom } from 'src/components/SearchFilters/atoms';
import type { FieldConfig } from 'src/components/SearchFilters/config/formConfigTypes';
import { hiddenFieldsAtom } from 'src/components/SearchFilters/hooks/useDetectHiddenFields';
import type { FormState } from 'src/components/SearchFilters/types';

import type { RealEstateKey } from 'src/types/real-estate';
import type { SearchListingData } from 'src/types/real-estate-lite';

import { removeEmptyKeys } from 'src/utils/object';

export type RealEstateListSearchWithLocalData = SearchListingData & {
  refill: RealEstateKey[];
  waitingForRefill: RealEstateKey[];
};

export const formSearchParamsAtom = createAtomicStateDerivedAtom({
  key: 'FormSearchParamsWithoutHidden',
  get({ get }) {
    const searchParams: FormState = {};

    const searchFormState = get(formStateAtom);

    // Removing hidden and empty fields from search params
    const hiddenFields: (FieldConfig | null)[] = get(hiddenFieldsAtom);
    const paramsToAdd = Object.keys(searchFormState).filter(
      (fieldName) =>
        !hiddenFields.find((hiddenField) => hiddenField?.field === fieldName)
    );

    paramsToAdd.forEach(
      (param) => (searchParams[param] = searchFormState[param])
    );

    return removeEmptyKeys(searchParams);
  },
});
