import { useMemo } from 'react';
import { useAuthContext } from '@indomita-react/auth-provider';
import { useTranslations } from '@pepita-react/i18n';

import { useSimpleForm } from 'src/hooks/useSimpleForm';
import { useAskToAgenciesDialogSchema } from './useAskToAgenciesDialogSchema';

import type { AskToAgenciesDialogData } from 'src/types/save-search';

import { useRealEstateSearchParams } from 'src/views/ListingSearch/hooks/useRealEstateSearchParams';

import { postAskToAgenciesDialog } from '../api';

export function useAskToAgenciesForm({
  onSuccess,
  searchID,
}: {
  onSuccess?: () => void;
  searchID: string;
}) {
  const { trans } = useTranslations();
  const { user } = useAuthContext();

  const realEstateSearchParams = useRealEstateSearchParams();

  const schema = useAskToAgenciesDialogSchema();
  const initialData = useMemo(
    () => ({
      searchID,
      searchParams: realEstateSearchParams,
      email: user?.email,
      name: user?.name,
      surname: user?.surname,
      phone: user?.phone,
      privacy: Boolean(user),
    }),
    [user, searchID, realEstateSearchParams]
  );

  const form = useSimpleForm<AskToAgenciesDialogData>({
    schema,
    initialData,
    onSubmit(data) {
      return postAskToAgenciesDialog(data);
    },
    onSuccess() {
      onSuccess?.();

      return trans('toast_search_sent_to_agencies');
    },
    onError() {
      return trans('lbl_generic_error');
    },
  });

  return { form };
}
