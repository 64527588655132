import { useTranslations } from '@pepita-react/i18n';
import clsx from 'clsx';
import { useAtomValue } from 'jotai';

import { searchInfoAtom } from 'src/entities/listing/atoms/searchInfoAtom';

import { useCheckSaveSearchDisabled } from '../hooks/useCheckSaveSearchDisabled';

import { SaveSearch } from '../index';

import css from './styles.module.scss';

export function SaveSearchBox({ customClass }: { customClass?: string }) {
  const { trans } = useTranslations();
  const searchInfo = useAtomValue(searchInfoAtom);

  const saveSearchDisabled = useCheckSaveSearchDisabled();

  if (searchInfo?.searchId || saveSearchDisabled) {
    return null;
  }

  return (
    <div className={clsx(css['in-saveSearch__box'], customClass)}>
      <div className={css['in-saveSearch__boxContent']}>
        <p className={css['in-saveSearch__boxText']}>
          {!searchInfo?.searchId
            ? trans('save_search_banner_text')
            : trans('tooltip_edit_search_text')}
        </p>
        {<SaveSearch position="box" />}
      </div>
    </div>
  );
}
