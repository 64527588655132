import { ENTITY_TYPE } from '@indomita-website/map-polygons/src/types';
import { useTranslations } from '@pepita-react/i18n';
import { captureException } from '@sentry/nextjs';

import { useAtomicStateAtomValue } from 'src/atoms/atomic-state';

import { geographySearchAtom } from 'src/components/ReactGeographySearch/atoms';
import { formConfigAtom } from 'src/components/SearchFilters/atoms';
import { FormFields } from 'src/components/SearchFilters/config/formConfigTypes';

import { isFeatureEnabled } from 'src/config/features-toggle';
import { getProductConfig } from 'src/config/product';

import { CATEGORIES, CONTRACT } from 'src/constants/typologies';

import {
  useCurrencyFormatterCallback,
  useSquareMetersFormatterCallback,
} from 'src/hooks/useFormatters';

import { ENERGY_RATE } from 'src/types/energy-rate';

import { nonNullable } from 'src/utils/common';
import {
  getMetroValue,
  getMultiselectValues,
  getRangeValues,
  getRoomsValue,
  getSelectValue,
  getWhere,
} from 'src/utils/searchBadges';
import { capitalize } from 'src/utils/string';

import { useRealEstateSearchParams } from 'src/views/ListingSearch/hooks/useRealEstateSearchParams';

export const useSearchBadges = (): string[] => {
  const badges: Nullable<string>[] = [];

  const { trans } = useTranslations();
  const currencyFormatter = useCurrencyFormatterCallback();
  const surfaceFormatter = useSquareMetersFormatterCallback();

  const geography = useAtomicStateAtomValue(geographySearchAtom);
  const realEstateSearchParams = useRealEstateSearchParams();
  const formConfig = useAtomicStateAtomValue(formConfigAtom);

  try {
    // Get category badge
    if (realEstateSearchParams[FormFields.CATEGORY]) {
      badges.push(
        // i18n-extract-mark-context-next-line ["1", "2", "3", "4", "5", "6", "14", "20", "21", "22", "23", "24", "25", "26"]
        trans('db_category.id', {
          context: realEstateSearchParams[FormFields.CATEGORY],
        })
      );
    }

    // Get geography info badge
    badges.push(getWhere(geography, trans));

    // Get typology badge
    if (
      realEstateSearchParams[FormFields.TYPOLOGY]?.length > 0 &&
      realEstateSearchParams[FormFields.CATEGORY] !== CATEGORIES.STANZE &&
      realEstateSearchParams[FormFields.CATEGORY] !==
        CATEGORIES.NEGOZI_LOCALI_COMMERCIALI
    ) {
      const typologyLabels = realEstateSearchParams[FormFields.TYPOLOGY].map(
        (typology) =>
          // Keys already extracted
          // i18n-extract-disable-next-line
          trans('db_typology.id', {
            context: typology,
          })
      );

      badges.push(typologyLabels.join(', '));
    } else {
      if (realEstateSearchParams[FormFields.CONTRACT] === CONTRACT.AFFITTO) {
        badges.push(trans('generic_house_rent'));
      } else {
        badges.push(trans('generic_house_sell'));
      }
    }

    // Get status badge
    if (
      realEstateSearchParams[FormFields.STATUS] &&
      realEstateSearchParams[FormFields.STATUS] !== ''
    ) {
      badges.push(
        // i18n-extract-mark-context-next-line ["1", "6", "2", "5"]
        trans('db_status.id', {
          context: realEstateSearchParams[FormFields.STATUS],
        })
      );
    }

    // get range values
    const prices = [
      realEstateSearchParams[FormFields.MIN_PRICE],
      realEstateSearchParams[FormFields.MAX_PRICE],
    ].map((value) => (value ? currencyFormatter(value, '€') : null));

    badges.push(getRangeValues(trans, prices[0], prices[1]));

    badges.push(
      getRangeValues(
        trans,
        realEstateSearchParams[FormFields.MIN_SURFACE]
          ? surfaceFormatter(realEstateSearchParams[FormFields.MIN_SURFACE], 0)
          : null,
        realEstateSearchParams[FormFields.MAX_SURFACE]
          ? surfaceFormatter(realEstateSearchParams[FormFields.MAX_SURFACE], 0)
          : null
      )
    );

    // Bathroom label
    if (realEstateSearchParams[FormFields.BATHROOMS]) {
      const label = getSelectValue(
        FormFields.BATHROOMS,
        realEstateSearchParams[FormFields.BATHROOMS],
        formConfig
      );

      badges.push(
        trans('search_formatter_nr_bathroom', {
          count: realEstateSearchParams[FormFields.BATHROOMS],
          params: label ? [label] : [],
        })
      );
    }

    // Floors label
    badges.push(
      getMultiselectValues(
        {
          '10': trans('tag_floor_ground'),
          '20': trans('tag_floor_middle'),
          '30': trans('tag_floor_top'),
        },
        trans('lbl_floor'),
        realEstateSearchParams[FormFields.FLOOR_ZONE]
      )
    );

    // Garden label
    badges.push(
      getMultiselectValues(
        {
          '10': trans('tag_garden_private'),
          '20': trans('tag_garden_shared'),
        },
        trans('lbl_garden'),
        realEstateSearchParams[FormFields.GARDEN]
      )
    );

    // Property type label
    if (realEstateSearchParams[FormFields.PROPERTY_TYPE]) {
      badges.push(
        capitalize(
          // i18n-extract-disable-next-line
          trans(
            getSelectValue(
              FormFields.PROPERTY_TYPE,
              realEstateSearchParams[FormFields.PROPERTY_TYPE],
              formConfig
            ) || ''
          )
        )
      );
    }

    // Garage type label
    badges.push(
      getMultiselectValues(
        {
          '1': trans('db_boxauto.id_1'),
          '3': trans('db_boxauto.id_3'),
          '4': trans('db_boxauto.id_4'),
        },
        trans('lbl_garage'),
        realEstateSearchParams[FormFields.BOX]
      )
    );

    // Heating label
    badges.push(
      getMultiselectValues(
        {
          '1': trans('autonomous_heating'),
          '2': trans('centralized_heating'),
          '10': trans('air_conditioning'),
        },
        trans('lbl_heating'),
        realEstateSearchParams[FormFields.HEATING]
      )
    );

    // Boolean fields
    if (realEstateSearchParams[FormFields.BASEMENT]) {
      badges.push(capitalize(trans('lbl_basement')));
    }

    if (realEstateSearchParams[FormFields.SWIMMING_POOL]) {
      badges.push(capitalize(trans('lbl_pool')));
    }

    if (realEstateSearchParams[FormFields.EXCLUDE_AUCTIONS]) {
      badges.push(capitalize(trans('hide_auctions')));
    }

    if (realEstateSearchParams[FormFields.BALCONY_OR_TERRACE]) {
      if (
        realEstateSearchParams[FormFields.BALCONY_OR_TERRACE].includes(
          'terrazzo'
        )
      ) {
        badges.push(trans('lbl_terrace'));
      }

      if (
        realEstateSearchParams[FormFields.BALCONY_OR_TERRACE].includes(
          'balcone'
        )
      ) {
        badges.push(trans('lbl_balcony'));
      }
    }

    // Rooms label
    badges.push(
      getRoomsValue(
        trans,
        {
          uniqueValue: 'search_formatter_nr_rooms', // i18n-extract-keys ["search_formatter_nr_rooms"]
          bothValues: 'search_formatter_nr_rooms_min_max', // i18n-extract-keys ["search_formatter_nr_rooms_min_max"]
          onlyMin: 'listing_meta_max_room', // i18n-extract-keys ["listing_meta_max_room"]
          onlyMax: 'listing_meta_min_room', // i18n-extract-keys ["listing_meta_min_room"]
        },
        realEstateSearchParams[FormFields.MIN_NUMBER_OF_ROOMS],
        realEstateSearchParams[FormFields.MAX_NUMBER_OF_ROOMS]
      )
    );

    if (isFeatureEnabled('BEDROOM_SEARCH_ENABLED')) {
      // bedroom label
      badges.push(
        getRoomsValue(
          trans,
          {
            uniqueValue: 'search_formatter_nr_bedrooms', // i18n-extract-keys ["search_formatter_nr_bedrooms"]
            bothValues: 'search_formatter_nr_bedrooms_min_max', // i18n-extract-keys ["search_formatter_nr_bedrooms_min_max"]
            onlyMin: 'listing_meta_min_bedroom', // i18n-extract-keys ["listing_meta_min_bedroom"]
            onlyMax: 'listing_meta_max_bedroom', // i18n-extract-keys ["listing_meta_max_bedroom"]
          },
          realEstateSearchParams[FormFields.MIN_BEDROOMS],
          realEstateSearchParams[FormFields.MAX_BEDROOMS]
        )
      );
    }

    // Building use label
    badges.push(
      getMultiselectValues(
        {
          '1': trans('db_building_use.id_1'),
          '10': trans('db_building_use.id_10'),
          '3': trans('db_building_use.id_3'),
          '11': trans('db_building_use.id_11'),
          '12': trans('db_building_use.id_12'),
          '13': trans('db_building_use.id_13'),
        },
        undefined,
        realEstateSearchParams[FormFields.BUILDING_USE]
      )
    );

    // Alarm system label
    if (realEstateSearchParams[FormFields.ALARM_SYSTEM]) {
      badges.push(trans('lbl_alarm_system_slash_supervision'));
    }

    // Furnished label
    if (realEstateSearchParams[FormFields.FURNISHED]) {
      badges.push(trans('lbl_furnished'));
    }

    // Auction label
    if (realEstateSearchParams[FormFields.CONTRACT] === CONTRACT.ASTE) {
      badges.push(capitalize(trans('detail_auction')));
    }

    // Elevator label
    if (realEstateSearchParams[FormFields.ELEVATOR]) {
      badges.push(trans('lbl_elevator'));
    }

    // Smoker label
    if (realEstateSearchParams[FormFields.SMOKER]) {
      badges.push(trans('smokers_allowed'));
    }

    // Animals label
    if (realEstateSearchParams[FormFields.ANIMALS]) {
      badges.push(trans('animals_allowed'));
    }

    // Energy rating label
    if (realEstateSearchParams[FormFields.ENERGY_RATING]) {
      const productName = getProductConfig('name');
      const energy2String = {} as { [key in ENERGY_RATE]: string };

      for (const energyRate in ENERGY_RATE) {
        energy2String[ENERGY_RATE[energyRate]] = energyRate;
      }

      const currentEnergyRate =
        energy2String[realEstateSearchParams[FormFields.ENERGY_RATING]];

      const translationKey =
        productName === 'indomio-es'
          ? 'Indomio.es_energy_rating'
          : 'Immobiliare.it_energy_rating';

      // i18n-extract-keys ["Immobiliare.it_energy_rating_high", "Immobiliare.it_energy_rating_medium", "Immobiliare.it_energy_rating_low"]
      // i18n-extract-keys ["Indomio.es_energy_rating_high", "Indomio.es_energy_rating_medium", "Indomio.es_energy_rating_low"]
      badges.push(
        `${trans('insertAd.energyRating')} - ${
          // i18n-extract-disable-next-line
          trans(translationKey, {
            context: currentEnergyRate,
          })
        }`
      );
    }

    // Metro search label
    // TODO: Why is it at the end of the badges?
    if (
      geography &&
      geography.searchType === 'place' &&
      geography.value.length > 0 &&
      (geography.value[0].type === ENTITY_TYPE.metro ||
        geography.value[0].type === ENTITY_TYPE.metroLine)
    ) {
      badges.push(getMetroValue(geography.value, trans));
    }
  } catch (e) {
    // Something got wrong
    captureException(e, {
      tags: {
        section: 'save-search-modal',
      },
    });
  }

  return badges.filter(nonNullable);
};
