import { useState } from 'react';
import { useTranslations } from '@pepita-react/i18n';
import { useBreakpoint } from '@pepita-react/use-breakpoint';
import clsx from 'clsx';

import { PepitaBadge } from 'src/libs/ui/pepita-badge';
import { PepitaFigure } from 'src/libs/ui/pepita-figure';
import {
  PepitaMediaObject,
  PepitaMediaObjectContent,
} from 'src/libs/ui/pepita-media-object';

import type { LocationPreviewType } from 'src/types/location-preview';

import css from './styles.module.scss';

export function SearchSummary({
  locationPreview,
  badges,
}: {
  locationPreview: LocationPreviewType;
  badges: string[];
}) {
  const { trans } = useTranslations();
  const [maxScreenWidthSm] = useBreakpoint({ to: 'sm' });

  const maxVisibleParams = maxScreenWidthSm ? 1 : 3;

  const [otherParams, setOtherParams] = useState(false);
  const [imagePreview, setImagePreview] = useState(locationPreview.main);

  return (
    <PepitaMediaObject customClass={css['in-searchSummary']} direction="col">
      <PepitaFigure
        bordered
        customClass={css['in-searchSummary__figure']}
        onError={() => {
          onerror = null;
          setImagePreview(locationPreview.alternate);
        }}
      >
        <PepitaFigure.Image src={imagePreview} width={328} height={109} />
      </PepitaFigure>

      <PepitaMediaObjectContent>
        <div className={css['in-searchSummary__filters']}>
          {badges.map((filter, i) => (
            <PepitaBadge
              key={i}
              customClass={clsx(
                css['in-searchSummary__badge'],
                !otherParams && i + 1 > maxVisibleParams
                  ? css['is-hidden']
                  : undefined
              )}
            >
              {filter}
            </PepitaBadge>
          ))}
          {!otherParams && badges.length > maxVisibleParams && (
            <button
              className={css['in-searchSummary__othersFilters']}
              onClick={() => {
                setOtherParams(true);
              }}
            >
              {trans('act_show_other_filter', {
                params: [badges.length - maxVisibleParams],
                count: badges.length - maxVisibleParams,
              })}
            </button>
          )}
        </div>
      </PepitaMediaObjectContent>
    </PepitaMediaObject>
  );
}
