import { useAuthContext } from '@indomita-react/auth-provider';
import { PrivacyCheckbox } from '@indomita-react/privacy-checkbox';
import { useTranslations } from '@pepita-react/i18n';

import type { SimpleForm } from 'src/hooks/useSimpleForm';

import { PepitaButton } from 'src/libs/ui/pepita-button';
import { PepitaTextField } from 'src/libs/ui/pepita-text-field';

import type {
  SaveSearchActionType,
  SaveSearchData,
} from 'src/types/save-search';

import css from './styles.module.scss';

interface DialogFooterProps {
  form: SimpleForm<SaveSearchData>;
  editSearch?: boolean;
  onSaveFromExistingClick: () => void;
}

export function DialogFooter({
  form,
  editSearch,
  onSaveFromExistingClick,
}: DialogFooterProps) {
  const { trans } = useTranslations();
  const { user } = useAuthContext();

  function handleSubmit(extra: { action: SaveSearchActionType }) {
    form.handleSubmit(extra);
  }

  return (
    <>
      {editSearch ? (
        <>
          <PepitaButton
            type="submit"
            variant="accent"
            onClick={() => {
              handleSubmit({ action: 'update' });
            }}
            block
          >
            {trans('act_update_existing_search')}
          </PepitaButton>
          <PepitaButton
            type="submit"
            variant="link"
            onClick={() => {
              onSaveFromExistingClick();
            }}
            block
          >
            {trans('act_save_new_search')}
          </PepitaButton>
        </>
      ) : (
        <>
          {!user && (
            <div className={css['in-saveSearchDialogFooter']}>
              <PepitaTextField
                type="email"
                name="email"
                floatingLabel
                label={trans('lbl_email')}
                placeholder={trans('act_insert_email')}
                status={form.getErrorMessage('email') ? 'error' : undefined}
                statusMessage={form.getErrorMessage('email')}
                onBlur={form.handleValidateTextInput('email')}
                onChange={form.handleTextInputChange('email')}
                defaultValue={form.data.email}
              />
              <PrivacyCheckbox
                error={form.getErrorMessage('privacy')}
                onChange={form.handleCheckboxChange('privacy')}
                value={Boolean(form.data.privacy)}
              />
            </div>
          )}

          <PepitaButton
            type="submit"
            variant="accent"
            disabled={form.submitButtonDisabled}
            loading={form.submitButtonLoading}
            onClick={() => handleSubmit({ action: 'save' })}
            block
          >
            {trans('act_activate_for_free')}
          </PepitaButton>
        </>
      )}
    </>
  );
}
