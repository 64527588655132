import { useMemo, useRef } from 'react';
import { ENTITY_TYPE } from '@indomita-website/map-polygons/src/types';
import { useAtomValue } from 'jotai';

import { useAtomicStateAtomValue } from 'src/atoms/atomic-state';

import { geographySearchAtom } from 'src/components/ReactGeographySearch/atoms';

import { searchInfoAtom } from 'src/entities/listing/atoms/searchInfoAtom';

import { deepEqual } from 'src/utils/object';

import { utmSourceAtom } from 'src/views/ListingSearch/atoms/utmSourceAtom';
import { useListingSearchParams } from 'src/views/ListingSearch/hooks/useListingSearchParams';

import { trackSendModalAction } from '../../metrics';

let LAST_SEARCH_PARAMS = null;
const lockedSources = ['trovakasa', 'bookmarkricerche'];

export const useAutoOpenSaveSearch = () => {
  const searchParams = useListingSearchParams();
  const geography = useAtomicStateAtomValue(geographySearchAtom);
  const searchInfo = useAtomValue(searchInfoAtom);
  const utmSource = useAtomValue(utmSourceAtom);
  const autoOpenValue = useRef(false);
  const resultsCount = searchInfo?.resultsCount;

  const isPrivateAndLuxurySearch =
    (geography?.searchType === 'place' &&
      (geography.value[0].type === ENTITY_TYPE.country ||
        geography.value[0].type === ENTITY_TYPE.region)) ||
    searchParams.lusso ||
    searchParams.noAgenzie === '1' ||
    searchParams.lowcost === '1' ||
    searchParams.seaView === '1' ||
    searchParams.seaDistance;

  const returnValue = useMemo(() => {
    try {
      // Disabling auto open on countries and regions and for private and
      // luxury searches
      if (isPrivateAndLuxurySearch) {
        autoOpenValue.current = false;

        return false;
      }

      // Avoid open modal when loading results
      if (searchInfo?.isFetching) {
        autoOpenValue.current = false;

        return false;
      }

      // Avoid double calling with same params due to updates
      if (
        deepEqual(
          { ...searchParams, ...searchInfo, resultsCount },
          LAST_SEARCH_PARAMS
        )
      ) {
        return autoOpenValue.current;
      }

      LAST_SEARCH_PARAMS = JSON.parse(
        JSON.stringify({ ...searchParams, ...searchInfo, resultsCount })
      );

      ////////////////////// FIRST CHECK //////////////////////
      // Show modal only when not in edit search mode
      if (searchInfo?.isEditing || searchInfo?.searchId) {
        trackSendModalAction('closed');
        autoOpenValue.current = false;

        return false;
      }

      ////////////////////// SECOND CHECK //////////////////////
      // Do not show modal if results count is null, undefined or 0
      if (!resultsCount) {
        trackSendModalAction('closed');
        autoOpenValue.current = false;

        return false;
      }

      ////////////////////// THIRD CHECK //////////////////////
      // Do not show modal if results count is > 500 or <= 9
      if (resultsCount > 500 || resultsCount <= 9) {
        trackSendModalAction('closed');
        autoOpenValue.current = false;

        return false;
      }

      ////////////////////// FOURTH CHECK //////////////////////
      // For some utm sources we do not want to open
      // the save search modal (alert email, site-v7...)
      if (lockedSources.includes(utmSource ?? '')) {
        trackSendModalAction('locked');
        autoOpenValue.current = false;

        return false;
      }

      // If we arrived here, we need to show the modal
      autoOpenValue.current = true;

      return true;
    } catch {
      autoOpenValue.current = false;

      return false;
    }
  }, [
    isPrivateAndLuxurySearch,
    resultsCount,
    searchInfo,
    searchParams,
    utmSource,
  ]);

  return returnValue;
};
