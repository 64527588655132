import React from 'react';
import { Button } from '@pepita-react/button';
import { Dialog, DialogFrameType } from '@pepita-react/dialog';
import { useTranslations } from '@pepita-react/i18n';

import type { SaveSearchActionType } from 'src/types/save-search';

import css from './styles.module.scss';

interface SaveFromExistingConfirmProps {
  onSubmit: (extra: {
    action: SaveSearchActionType;
    fromConfirmationDialogAction?: boolean;
  }) => void;
  onClose: () => void;
}

export function SaveFromExistingConfirmDialog({
  onSubmit,
  onClose,
}: SaveFromExistingConfirmProps) {
  const { trans } = useTranslations();

  return (
    <Dialog
      title={trans('lbl_new_save_search_confirmation_modal')}
      frameCustomClass={css['in-saveFromExistingConfirmDialog']}
      headerCustomClass={css['in-saveFromExistingConfirmDialog__header']}
      size={DialogFrameType.DIALOG}
      content={
        <p
          dangerouslySetInnerHTML={{
            __html: trans('saving_similar_search_message'),
          }}
        />
      }
      contentCustomClass={css['in-saveFromExistingConfirmDialog__content']}
      footer={
        <>
          <Button
            type="submit"
            variant="link"
            onClick={() => {
              onSubmit({
                action: 'saveFromExisting',
                fromConfirmationDialogAction: true,
              });
            }}
          >
            {trans('act_save_new_search')}
          </Button>
          <Button
            type="submit"
            variant="accent"
            onClick={() => {
              onSubmit({
                action: 'update',
                fromConfirmationDialogAction: true,
              });
            }}
            customClass={css['in-saveFromExistingConfirmDialog__updateButton']}
          >
            {trans('act_update_existing_search')}
          </Button>
        </>
      }
      footerCustomClass={css['in-saveFromExistingConfirmDialog__footer']}
      onClose={onClose}
    />
  );
}
