import { useCallback } from 'react';

import {
  useAtomicStateAtomSetState,
  useAtomicStateStateAtom,
} from 'src/atoms/atomic-state';

import { formStateAtom } from 'src/components/SearchFilters/atoms';
import type { FormFields } from 'src/components/SearchFilters/config/formConfigTypes';
import type {
  FormState,
  FormStateRef,
} from 'src/components/SearchFilters/types';

import { useAtomSelector } from './useAtomSelector';

import { produce } from 'src/utils/immer';

export function useSearchFormState<S>(
  field: FormFields,
  stateAtom: FormStateRef = formStateAtom
): [S, (state: S | ((prev: S) => S)) => void] {
  const formData = useAtomSelector<S>(stateAtom, field);
  const setFormState = useAtomicStateAtomSetState(stateAtom);

  const setFieldData = useCallback(
    (updater) => {
      setFormState((state) => {
        return {
          ...state,
          [field]:
            typeof updater === 'function'
              ? produce(state[field], (draft) => updater(draft))
              : updater,
        };
      });
    },
    [field, setFormState]
  );

  return [formData, setFieldData];
}

export function useSearchFormSelectionValues(
  fields: FormFields[],
  stateAtom: FormStateRef = formStateAtom
): FormState {
  const [state] = useAtomicStateStateAtom(stateAtom);

  const formData = {};

  (fields || []).forEach((field) => {
    formData[field] = state[field];
  });

  return formData;
}
