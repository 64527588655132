import { useState } from 'react';
import { useTranslations } from '@pepita-react/i18n';

import AlertError from '../AlertError';
import { NotificationOptions } from '../NotificationOptions';
import { NotificationHeading } from '../NotificationOptions/NotificationHeading';
import { ToggleSwitch } from '../NotificationOptions/ToggleSwitch';

export type ToggleSwitchValue = {
  flagContactsEnabled?: boolean;
};

interface ReceiveContactsSectionProps {
  value: ToggleSwitchValue;
  showFlagContactsField: boolean;
  handleChange: (update: (data: ToggleSwitchValue) => void) => void;
  errorMessage: string | null;
  disabledSwitch?: boolean;
  activeSearches?: number;
  maxSavedSearches?: number;
}

export function ReceiveContactsSection({
  value,
  handleChange,
  showFlagContactsField,
  errorMessage,
  disabledSwitch = false,
  activeSearches,
  maxSavedSearches,
}: ReceiveContactsSectionProps) {
  const { trans } = useTranslations();
  const [showAlert, setShowAlert] = useState(false);

  const headerCounter = `(${activeSearches}/${maxSavedSearches})`;

  return (
    <NotificationOptions dataCy={'active-save-search-section'}>
      <NotificationHeading
        icon="chat"
        title={trans('lbl_save_search_dialog_receive_contacts')}
        badgeLabel="New"
        counter={headerCounter}
        tooltipText={trans('lbl_tooltip_active_save_search')}
      />

      {showFlagContactsField && (
        <>
          <ToggleSwitch
            label={trans('lbl_save_search_dialog_secret_published')}
            disabled={disabledSwitch}
            checked={value.flagContactsEnabled}
            onChange={(event) => {
              const isChecked = event.target.checked;

              if (errorMessage && isChecked) {
                /**
                 *  Show switch activation for 200ms before deactivating it.
                 * This allows the user to see the switch turn on before it is turned off automatically
                 *  */
                setTimeout(() => {
                  setShowAlert(true);
                  handleChange((data) => {
                    data.flagContactsEnabled = false;
                  });
                }, 200);
              }

              handleChange((data) => {
                data.flagContactsEnabled = isChecked;
              });
            }}
          />

          <AlertError show={showAlert} message={errorMessage} />
        </>
      )}

      {!showFlagContactsField && errorMessage ? <p>{errorMessage}</p> : null}
    </NotificationOptions>
  );
}
